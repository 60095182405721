
import { defineComponent } from 'vue'
import '@progress/kendo-ui/js/kendo.tooltip'
import { KendoTooltip } from '@progress/kendo-popups-vue-wrapper'

export default defineComponent({
  components: {
    KendoTooltip
  },
  props: {
    disabled: Boolean
  },
  setup() {
    return {}
  }
})
