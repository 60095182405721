
import { defineComponent, ref, markRaw } from 'vue'
import '@progress/kendo-ui/js/kendo.treeview'
import { TreeView } from '@progress/kendo-treeview-vue-wrapper'
import '@progress/kendo-ui/js/kendo.data'
import FilterWrapper from '@/components/Filter/FilterWrapper.vue'
import FilterTextInput from '@/components/Filter/FilterTextInput.vue'
import GridPageInfo from '@/components/Grid/GridPageInfo.vue'
import BaseButton from '@/components/Button/BaseButton.vue'
import MacBroadcastCard from '@/components/Card/MacBroadcastCard.vue'
import KendoPager from '@/components/Kendo/KendoPager.vue'

import { treeViewData, treeViewSchema } from '@/mock/data/host'

export default defineComponent({
  components: {
    TreeView,
    FilterWrapper,
    FilterTextInput,
    GridPageInfo,
    BaseButton,
    MacBroadcastCard,
    KendoPager
  },
  setup() {
    // FIXME：box-panel-half 尚未元件化

    // TreeView 相關
    const template =
      '# for (i = 0; i < item.icons.length; i++ ) { #' +
      '<span class="tree-icon ' +
      '#: item.icons[i] #' +
      '"></span>' +
      '# } #' +
      '#: item.name #' +
      '<span class="child-count">' +
      '#: Number(item.count).toLocaleString() #' + // 1000 -> "1,000"
      '</span>'

    const localDataSource = markRaw(
      // eslint-disable-next-line
        new kendo.data.HierarchicalDataSource({
        data: treeViewData,
        schema: treeViewSchema
      })
    )

    // 篩選相關，文字輸入
    const textInput = ref('')

    const isTreeViewOpen = ref(true)

    return {
      template,
      localDataSource,
      isTreeViewOpen,
      textInput
    }
  }
})
