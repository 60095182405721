<template>
  <div class="mac-card px-0">
    <template v-if="disabled">
      <span class="conflict-msg text-info"
        ><SvgIcon icon="warning" />需輸入 Switch [Write Community]</span
      >
    </template>
    <div class="card-block">
      <img
        class="mac-img"
        style="width: 75px; height: 98px;"
        :src="require('@/assets/images/img/mac-broadcast@2x.png')"
      />
      <div class="info-row">
        <span class="info-name">衝突 MAC</span
        ><span
          class="info-value copy-wrapper"
          v-clipboard="'00195BF4EF48'"
          v-clipboard:success="
            () => this.$toast.show('00195BF4EF48', 'success')
          "
        >
          <KendoTooltip id="tooltip" title="點擊複製" :position="'right'">
            <span>00195BF4EF48</span>
            <SvgIcon icon="copy" class="copy-icon" />
          </KendoTooltip>
        </span>
      </div>
      <div class="info-row">
        <span class="info-name">主機名稱</span
        ><span class="info-value">Henry_PC</span>
      </div>
      <div class="info-row">
        <span class="info-name">Switch ID</span
        ><span class="info-value">C6513-E</span>
      </div>
      <div class="info-row">
        <span class="info-name">Switch Port</span
        ><span class="info-value"
          >C3850-24T for 3F
          <div class="operation-btn">
            <SvgIcon icon="dot-more" />
          </div>
        </span>
      </div>
      <div class="info-row">
        <span class="info-name">封包大小</span
        ><span class="info-value text-danger">77675 Bytes / Sec</span>
      </div>
      <div class="info-row">
        <span class="info-name">事件時間</span
        ><span class="info-value">2020/08/16 15:15:47</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import '@progress/kendo-ui/js/kendo.tooltip'
import { KendoTooltip } from '@progress/kendo-popups-vue-wrapper'

export default defineComponent({
  components: {
    KendoTooltip
  },
  props: {
    disabled: Boolean
  },
  setup() {
    return {}
  }
})
</script>

<style scoped></style>
